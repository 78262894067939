/* Reset */

html, body {
  height: 100%;
  width: 100%;
}

html, body, div, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-size: 100%;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a, a:hover {
  color: inherit;
  text-decoration: none;
}



/*h1, h2, h3, h4, h5, h6 {
  font-family: 'Droid Sans Mono', monospace;
}*/

h1 {
  font-size: 18px;
  line-height: 24px;
}

h2 {
  color: #4f7cf7;
  font-size: 14px;
  font-family: 'Droid Sans Mono';
}

h3 {
  color: rgba(27, 31, 48, 0.4);
  line-height: 24px;
  font-size: 13px;
  margin-bottom: 6px;
  font-weight: 500;
}

html, body, div, span, applet, object, iframe, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  font-family: 'Open Sans', sans-serif;
}

textarea, input { outline: none; }

@-webkit-keyframes shift-rightwards{
  0% {
    -webkit-transform:translateX(-100%);
            transform:translateX(-100%)
  }
  40% {
    -webkit-transform:translateX(0);
            transform:translateX(0)
  }
  60% {
    -webkit-transform:translateX(0);
            transform:translateX(0)
  }
  100% {
    -webkit-transform:translateX(100%);
            transform:translateX(100%)
  }
}

@keyframes shift-rightwards{
  0% {
    -webkit-transform:translateX(-100%);
            transform:translateX(-100%)
  }
  40% {
    -webkit-transform:translateX(0);
            transform:translateX(0)
  }
  60% {
    -webkit-transform:translateX(0);
            transform:translateX(0)
  }
  100% {
    -webkit-transform:translateX(100%);
            transform:translateX(100%)
  }
}

